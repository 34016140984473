import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      en: {
        translation: {
         menu_projects: "Projects",
         menu_skills: "Skills",
        menu_contact: "Contact",
        home_welcome: "Welcome",
        home_welcome2: "you were expected.",
        }
      },
      ja: {
        translation: {
         menu_projects: "事例",
        menu_skills: "能力",
        menu_contact: "お問い合わせ",
        home_welcome: "ようこそ",
        home_welcome2: "お待ちしておりました",
        }
      },
    }
  });

export default i18n;